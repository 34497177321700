var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gov-proposal-list"},[(!_vm.windowMode)?_c('h2',{staticClass:"dt-heading"},[_vm._v(" Proposals "),_c('span',{staticClass:"f-records-count"},[_vm._v("("+_vm._s(_vm.totalCount)+")")])]):_vm._e(),_c('f-card',{staticClass:"account-transaction-list-dt",attrs:{"off":_vm.windowMode}},[(!_vm.proposalsError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.pageInfo.hasNext,"mobile-view":_vm.cMobileView,"loading":_vm.loading,"infinite-scroll":"","fixed-header__":"","action-on-row":"","f-card-off":""},on:{"fetch-more":_vm.fetchMore,"row-action":_vm.onRowAction},scopedSlots:_vm._u([{key:"column-name",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-8"},[_vm._v(" "+_vm._s(parseInt(item.proposal.id, 16))+" "),_c('br'),_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('a',{staticClass:"break-word",attrs:{"href":(_vm.explorerUrl + "address/" + (item.proposal.contract)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(item.proposal.contract))+" ")])])]):[_vm._v(" "+_vm._s(parseInt(item.proposal.id, 16))+" "),_c('br'),_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('a',{staticClass:"break-word",attrs:{"href":(_vm.explorerUrl + "address/" + (item.proposal.contract)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(item.proposal.contract))+" ")])]]}},{key:"column-startend",fn:function(ref){
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-8"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.timestampToDate(item.proposal.votingStarts), true, true))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatDate(_vm.timestampToDate(item.proposal.votingMustEnd), true, true))+" ")])]):[_vm._v(" "+_vm._s(_vm.formatDate(_vm.timestampToDate(item.proposal.votingStarts), true, true))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatDate(_vm.timestampToDate(item.proposal.votingMustEnd), true, true))+" ")]]}},{key:"column-votes",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(value)?_c('f-colored-number-range',{attrs:{"value":value.votes,"colors":_vm.overallVotesColors(value.minVotes),"show-percentage":"","percentage-frac-digits":_vm.fracDigits}}):_vm._e()],1)]):[(value)?_c('f-colored-number-range',{attrs:{"value":value.votes,"colors":_vm.overallVotesColors(value.minVotes),"show-percentage":"","percentage-frac-digits":_vm.fracDigits}}):_vm._e()]]}},{key:"column-voted",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(value)?[_vm._v(_vm._s(value.voted)+"/"+_vm._s(value.total))]:_vm._e()],2)]):[(value)?[_vm._v(_vm._s(value.voted)+"/"+_vm._s(value.total))]:_vm._e()]]}},{key:"column-detail",fn:function(ref){
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('button',{staticClass:"btn",staticStyle:{"margin-top":"8px"}},[_vm._v("Detail")])])]):[_c('button',{staticClass:"btn"},[_vm._v("Detail")])]]}}],null,false,1571513846)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.proposalsError))])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }