import { render, staticRenderFns } from "./AccountHeader.vue?vue&type=template&id=2c4daf34&"
import script from "./AccountHeader.vue?vue&type=script&lang=js&"
export * from "./AccountHeader.vue?vue&type=script&lang=js&"
import style0 from "./AccountHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports