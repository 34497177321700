var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pairs-list"},[(_vm.items.length === 0)?_c('div',[_vm._v("No pairs")]):_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"f-card-off":"","columns":_vm.columns,"items":_vm.items,"mobile-view":_vm.mobileView},scopedSlots:_vm._u([{key:"column-pair",fn:function(ref){
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-uniswap-pair-symbol',{attrs:{"pair":item,"router-link-to":{
                            name: 'funiswap-pair-detail',
                            params: { pairAddress: item.pairAddress },
                        }}})],1)]):[_c('f-uniswap-pair-symbol',{attrs:{"pair":item,"router-link-to":{ name: 'funiswap-pair-detail', params: { pairAddress: item.pairAddress } }}})]]}},{key:"column-liquidityA",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('div',{staticClass:"token-liquidity"},[_vm._v(" "+_vm._s(value._total)+" "),_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"24px","img-height":"24px"}})],1)])]):[_c('div',{staticClass:"token-liquidity"},[_vm._v(" "+_vm._s(value._total)+" "),_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"24px","img-height":"24px"}})],1)]]}},{key:"column-liquidityB",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('div',{staticClass:"token-liquidity"},[_vm._v(" "+_vm._s(value._total)+" "),_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"24px","img-height":"24px"}})],1)])]):[_c('div',{staticClass:"token-liquidity"},[_vm._v(" "+_vm._s(value._total)+" "),_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"24px","img-height":"24px"}})],1)]]}},{key:"column-price",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(value[0]._perPrice)?[_vm._v(" "+_vm._s(value[0]._perPrice.toFixed(4))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$defi.getTokenSymbol(value[0]))+"/"+_vm._s(_vm.$defi.getTokenSymbol(value[1]))+" ")]:[_vm._v("0")]],2)]):[(value[0]._perPrice)?[_vm._v(" "+_vm._s(value[0]._perPrice.toFixed(4))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$defi.getTokenSymbol(value[0]))+"/"+_vm._s(_vm.$defi.getTokenSymbol(value[1]))+" ")]:[_vm._v("0")]]]}},{key:"column-position",fn:function(ref){
                        var item = ref.item;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(_vm.getPairShare(item) > 0)?[_c('f-token-value',{attrs:{"token":item.tokens[0],"value":_vm.getTokenShare(item, 0)}}),_c('br'),_c('f-token-value',{attrs:{"token":item.tokens[1],"value":_vm.getTokenShare(item, 1)}})]:[_vm._v(" - ")]],2)]):[(_vm.getPairShare(item) > 0)?[_c('f-token-value',{attrs:{"token":item.tokens[0],"value":_vm.getTokenShare(item, 0)}}),_c('br'),_c('f-token-value',{attrs:{"token":item.tokens[1],"value":_vm.getTokenShare(item, 1)}})]:[_vm._v(" - ")]]]}},{key:"column-actions",fn:function(ref){
                        var item = ref.item;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('router-link',{attrs:{"to":{
                            name: 'funiswap-add-liquidity',
                            params: {
                                tokena: item.tokens[0].address,
                                tokenb: item.tokens[1].address,
                                address: _vm.currAccountAddress,
                            },
                        }}},[_vm._v(" Add Liquidity ")]),_vm._v(" , "),_c('router-link',{attrs:{"to":{
                            name: 'funiswap-swap',
                            params: {
                                tokena: item.tokens[0].address,
                                tokenb: item.tokens[1].address,
                                address: _vm.currAccountAddress,
                            },
                        }}},[_vm._v(" Swap ")]),(item.shareOf && item.shareOf !== '0x0')?[_vm._v(" , "),_c('router-link',{attrs:{"to":{
                                name: 'funiswap-remove-liquidity',
                                params: {
                                    tokena: item.tokens[0].address,
                                    tokenb: item.tokens[1].address,
                                    address: _vm.currAccountAddress,
                                },
                            }}},[_vm._v(" Remove Liquidity ")])]:_vm._e()],2)]):[_c('router-link',{attrs:{"to":{
                        name: 'funiswap-add-liquidity',
                        params: {
                            tokena: item.tokens[0].address,
                            tokenb: item.tokens[1].address,
                            address: _vm.currAccountAddress,
                        },
                    }}},[_vm._v(" [+] ")]),_c('router-link',{attrs:{"to":{
                        name: 'funiswap-remove-liquidity',
                        params: {
                            tokena: item.tokens[0].address,
                            tokenb: item.tokens[1].address,
                            address: _vm.currAccountAddress,
                        },
                    }}},[_vm._v(" [-] ")]),_c('br'),_c('router-link',{attrs:{"to":{
                        name: 'funiswap-swap',
                        params: {
                            tokena: item.tokens[0].address,
                            tokenb: item.tokens[1].address,
                            address: _vm.currAccountAddress,
                        },
                    }}},[_vm._v(" Swap ")]),(item.shareOf && item.shareOf !== '0x0')?[_c('br')]:_vm._e()]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }